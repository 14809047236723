<template>
    <transition name="fade" mode="out-in">
        <div class="loader">
            <loader :size="50" />
        </div>
    </transition>
</template>

<script>

export default {
    name: "FullscreenLoader",
    components: {
        loader: () => import(/* webpackChunkName: "loader"*/ "@c/atoms/loader.vue")
    }
}
</script>

<style lang="scss" scoped>
.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, .8);
    top: 0;
    left: 0;
    z-index: 999999999;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
